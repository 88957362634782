import { Box } from "@mui/material";
import { PuffLoader } from "react-spinners";
import css from "./Loading.module.scss";

export default function Component({ loading, className, size = 100, fullscreen = false, ...props }) {
  const classes = fullscreen ? css._fullscreen : css._template;

  return (
    <Box className={classes} {...props}>
      <PuffLoader color={"#8E7280"} loading={loading} size={size} />
    </Box>
  );
}
