import React, { useState } from "react";

export const SnackbarContext = React.createContext({
  setSnackbar: () => { },
  snackbar: {}
});

export const SnackbarContainer = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    message: '',
    type: 'default',
    open: false
  });

  const handleSnackbarSet = (message, open = true, type = 'default') => {
    setSnackbar({
      message, type, open
    })
  };

  const contextValue = {
    setSnackbar: handleSnackbarSet,
    snackbar
  };
  return (
    <SnackbarContext.Provider value={contextValue}>
      {children}
    </SnackbarContext.Provider>
  )
}