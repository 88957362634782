import React from "react";
import LazyLoad from "react-lazyload";
import PropTypes from "prop-types";
import { Loading } from "../";

function Component({
  loadingClassName = null,
  className,
  children,
  loadingStyle = {},
  loadingSize,
}) {
  return (
    <LazyLoad
      offset={-100}
      placeholder={
        <Loading
          className={loadingClassName}
          size={loadingSize}
          style={loadingStyle}
        />
      }
      className={className}
    >
      {children}
    </LazyLoad>
  );
}

Component.propTypes = {
  loadingClassName: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.number,
  fullscreen: PropTypes.bool,
  loadingStyle: PropTypes.object,
  loadingSize: PropTypes.number,
};

export default Component;
