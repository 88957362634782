import { Box } from "@mui/material";
import css from "./AmplopDigital.module.scss";
import { mandiri, QR_CODE } from "../../../assets";
import CopyToClipboard from "react-copy-to-clipboard";
import { useNotif } from "../../../helpers/utils";
import { LazyLoad } from "../";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

function Component() {
  const { notif } = useNotif();
  const { donate } = useSelector((state) => state.details);

  const [bankData, setBankData] = useState([]);

  const handleCopyText = async (result) => {
    if (result) notif("Berhasil disalin");
  };

  useEffect(() => {
    const bank = donate?.map((item) => {
      const data = {
        logo: mandiri,
        number: item.account_number,
        name: item.account_name,
        color:
          "linear-gradient(180deg, rgba(24,61,110,1) 55%, rgba(253,183,19,1) 55%)",
        qr_code: QR_CODE,
      };
      return data;
    });

    setBankData(bank);
  }, [donate]);

  return (
    <>
      <Box className={css.amplop__wrapper}>
        {bankData?.map((bank, idx) => (
          <>
            <Box
              key={idx}
              className={css.card}
              data-aos={"fade-up"}
              data-aos-delay={400}
            >
              <Box className={css.card__content}>
                <LazyLoad
                  loadingSize={30}
                  loadingStyle={{ justifyContent: "flex-start" }}
                >
                  <img src={bank.logo} alt="Bank" className={css.card__logo} />
                </LazyLoad>
                <Box>
                  <Box className={`${css.card__number__container}`}>
                    <Box className={`${css.card__number} header-4`}>
                      {bank.number}
                    </Box>

                    <CopyToClipboard
                      text={bank.number}
                      onCopy={(text, result) => handleCopyText(result)}
                    >
                      <Box className={css.card__number__copy}>salin</Box>
                    </CopyToClipboard>
                  </Box>
                  <Box className={`${css.card__name} text-body`}>
                    {bank.name}
                  </Box>
                </Box>
              </Box>
              <Box
                className={css.card__object}
                sx={{ background: bank.color }}
              />
            </Box>
            <Box
              className={css.card__qrcode}
              data-aos={"fade-up"}
              data-aos-delay={600}
            >
              <LazyLoad loadingSize={100}>
                <img src={bank.qr_code} alt="QR Code" />
              </LazyLoad>
            </Box>
          </>
        ))}
      </Box>
    </>
  );
}

export default Component;
