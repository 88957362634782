import React from "react"
import { Snackbar } from "@mui/material";
import { useContext } from "react"
import { SnackbarContext } from "./SnackbarContext"

export const SnackbarDialog = () => {
  const { snackbar, setSnackbar } = useContext(SnackbarContext);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setSnackbar('', false);
  };

  const { message, open } = snackbar;

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message={message ? <span>{message}</span> : null}
      />
    </div>
  )
}