import { applyMiddleware } from "redux";
import { createStore } from "redux";
import thunk from "redux-thunk";

const initialState = {
  isTablet: false,
  prayers: [],
  pagination: {},
  refresh: 0,
  details: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case 'DIMENSION':
      return {...state, isTablet: action.payload}
    case 'PRAYERS':
      return {...state, prayers: action.payload}
    case 'PAGINATION':
      return {...state, pagination: action.payload}
    case 'REFRESH':
      return {...state, refresh: state.refresh + 1}
    case 'DETAILS':
      return {...state, details: action.payload}
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware(thunk));

export default store;